$primary: #039be5;
$primaryFont: #eee;

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;

  background: #fafafa;
  font-family: sans-serif;
  height: 100vh;
  min-width: 300px;
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 500px) {
  body {
    font-size: .6em;
  }

  .md {
    display: none;
  }

  .timeline-header-auxiliary {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    flex: 0 0 auto;
  }

}

.timeline-headers {
  flex: 0 0 auto;

  align-items: stretch;
  display: flex;

  background: #ddd;
  border-bottom: 1px solid #eee;

  .timeline-header {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding-bottom: .5em;
    text-align: center;

    h2 {
      background: $primary;
      color: $primaryFont;
      font-size: 1.5em;
      margin: 0 0 .5em 0;
      padding: 1em;
    }

    p {
      margin-bottom: .3em;
      margin-top: 0;
      padding: 0 1.1em;
    }

    .example {
      font-size: .9em;
      font-style: italic;
    }

  }

  .timeline-header-auxiliary {
    flex: 0 0 auto;

    h2 {
      font-weight: 400;
    }
  }
}

.timelines {
  flex: 1;

  display: flex;

  cursor: pointer;
  user-select: none;

  .timeline {
    flex: 1;

    overflow: hidden;
    position: relative;
    text-align: center;

    .timeline-item {
      $size: 3vh;

      left: 50%;
      position: absolute;
      transform: translate(-50%, $size);

      background: $primary;
      border: 1px solid #aaa;
      border-radius: 50%;
      color: $primaryFont;
      font-size: $size * 0.7;
      font-weight: bold;
      height: $size;
      line-height: $size;
      text-transform: uppercase;
      width: $size;
    }

  }

  .timeline-auxiliary {
    flex: 0 40px;
  }
}

.emit-event {
  $background: #bbb;

  height: 100px;
  width: 100%;

  background: $background;
  border: 0;
  color: #222;
  cursor: pointer;
  outline: 0;
  transition: background .2s;

  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;

  &:hover {
    background: darken($background, 5%);
  }

  strong {
    display: block;
    font-size: 1.8em;
    margin-bottom: .2em;
    text-transform: uppercase;
  }

  small {
    font-size: 1.2em;
  }
}
